export const GET_PATIENTDATA_SUCCESS = " GET_PATIENTDATA_SUCCESS";
export const GETBASERHYTHM = " GETBASERHYTHM";
export const GETACUITY = " GETACUITY";
export const IS_SUBMITTING_REPORT = " IS_SUBMITTING_REPORT";
export const SUBMITREVIEW = " SUBMITREVIEW";
export const SETDEFAULTSUBMITSTATUS = " SETDEFAULTSUBMITSTATUS";
export const SHOWTOAST = " SHOWTOAST";
export const IS_LOADING_CANCEL_REVIEW = " IS_LOADING_CANCEL_REVIEW";
export const REVIEWSTATUS = " REVIEWSTATUS";
export const EMPTYPATIENTDATA = " EMPTYPATIENTDATA";
export const SUMBITPATIENTREPORTID = " SUMBITPATIENTREPORTID";
export const IS_LOADING_PATIENT_DATA = " IS_LOADING_PATIENT_DATA";
export const IS_ERROR_GETTING_PATIENT_DATA = " IS_ERROR_GETTING_PATIENT_DATA";
export const IS_ERROR_GETTING_RHYTHM_DATA = " IS_ERROR_GETTING_RHYTHM_DATA";
export const IS_ERROR_GETTING_ACUITY_DATA = " IS_ERROR_GETTING_ACUITY_DATA";
