import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  patientInfo: {},
  recordingSample: null,
  reviewDetail: null,
  isLoadingPatientData: false,
  errorGettingPatientData: "",
  errorGettingBaseRhythm: false,
  errorGettingAcuity: false,
  baseRhythm: [],
  acuity: [],
  isSubmittingReview: false,
  isReviewSubmitted: 0,
  showToast: false,
  isLoadingCancelReview: false,
  reviewStatus: false,
  reportId: "",
};

const getPatientData = (state, action) => {
  return updateObject(state, {
    patientInfo: action.payload.patientDetail,
    recordingSample: action.payload.recordingSample,
    reviewDetail: action.payload.reviewDetail,
  });
};

const setIsLoadingPatientData = (state, action) => {
  return updateObject(state, {
    isLoadingPatientData: action.payload,
  });
};

const setIsSubmittingReview = (state, action) => {
  return updateObject(state, {
    isSubmittingReview: action.payload,
  });
};

const errorInGettingPatientData = (state, action) => {
  return updateObject(state, {
    errorGettingPatientData: action.payload,
  });
};

const setIsErrorGettingBaseRhythmData = (state, action) => {
  return updateObject(state, {
    errorGettingBaseRhythm: action.payload,
  });
};

const setIsErrorGettingAcuityData = (state, action) => {
  return updateObject(state, {
    errorGettingAcuity: action.payload,
  });
};

const submitPatientReportId = (state, action) => {
  return updateObject(state, {
    reportId: action.payload,
  });
};

const emptyPatientData = (state, action) => {
  return updateObject(state, {
    patientInfo: {},
    recordingSample: null,
    reviewDetail: null,
  });
};

const getBaseRythmData = (state, action) => {
  return updateObject(state, {
    baseRhythm: action.payload,
  });
};

const getAcuity = (state, action) => {
  return updateObject(state, {
    acuity: action.payload,
  });
};

const showToastHandler = (state, action) => {
  return updateObject(state, {
    showToast: action.payload,
  });
};

const submitReview = (state, action) => {
  return updateObject(state, {
    isReviewSubmitted: action.payload,
  });
};

const isLoadingCancelReview = (state, action) => {
  return updateObject(state, {
    isLoadingCancelReview: action.payload,
  });
};

const reviewStatus = (state, action) => {
  return updateObject(state, {
    reviewStatus: action.payload,
  });
};

const setDefaultSubmitStatus = (state, action) => {
  return updateObject(state, {
    isReviewSubmitted: 0,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PATIENTDATA_SUCCESS:
      return getPatientData(state, action);
    case types.GETBASERHYTHM:
      return getBaseRythmData(state, action);
    case types.GETACUITY:
      return getAcuity(state, action);
    case types.IS_SUBMITTING_REPORT:
      return setIsSubmittingReview(state, action);
    case types.SUBMITREVIEW:
      return submitReview(state, action);
    case types.SETDEFAULTSUBMITSTATUS:
      return setDefaultSubmitStatus(state, action);
    case types.IS_LOADING_CANCEL_REVIEW:
      return isLoadingCancelReview(state, action);
    case types.REVIEWSTATUS:
      return reviewStatus(state, action);
    case types.EMPTYPATIENTDATA:
      return emptyPatientData(state, action);
    case types.SUMBITPATIENTREPORTID:
      return submitPatientReportId(state, action);
    case types.SHOWTOAST:
      return showToastHandler(state, action);
    case types.IS_LOADING_PATIENT_DATA:
      return setIsLoadingPatientData(state, action);
    case types.IS_ERROR_GETTING_PATIENT_DATA:
      return errorInGettingPatientData(state, action);
    case types.IS_ERROR_GETTING_RHYTHM_DATA:
      return setIsErrorGettingBaseRhythmData(state, action);
    case types.IS_ERROR_GETTING_ACUITY_DATA:
      return setIsErrorGettingAcuityData(state, action);
    default:
      return state;
  }
};

export default reducer;
