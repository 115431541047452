import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";
import { REPORT_TYPE_MENU_OPTIONS } from "../../consts/ekgReportMenuOptionsConstants";

export const isClearClickedStatus = (status) => {
  return {
    type: types.GETCLEARCLICKEDSTATUS,
    payload: status,
  };
};

export const noReportForSelectedFilters = (status) => {
  return {
    type: types.NO_REPORT_FOR_SELECTED_FILTERS,
    payload: status,
  };
};

export const isErrorGeneratingReport = (status) => {
  return {
    type: types.IS_ERROR_GENERATING_REPORT,
    payload: status,
  };
};

export const getMembersData = (teamId) => {
  return (dispatch) => {
    CrpAPI.get(
      `https://us-crp-develop-kardia-k8s.development.alivecor.net/i/v1/teams/${teamId}/members`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETMEMBERSDATA,
            payload: res.data.members,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const exportReport = (reportDetails) => {
  let reportType;
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[0].reportType) {
    reportType = "Completed";
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[1].reportType) {
    reportType = "Queue";
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[2].reportType) {
    reportType = "Overdue";
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[3].reportType) {
    reportType = "CompletedOverdue";
  }
  return (dispatch) => {
    CrpAPI.get(
      `report?data=${JSON.stringify({ ...reportDetails, reportType })}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/csv",
        },
      }
    )
      .then((response) => {
        dispatch(noReportForSelectedFilters(false));
        dispatch(isErrorGeneratingReport(false));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(noReportForSelectedFilters(false));
        dispatch({
          type: types.SUBMIT_REPORT_BASED_ON_FILTER,
          payload: true,
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          dispatch(noReportForSelectedFilters(true));
          return;
        }
        dispatch(isErrorGeneratingReport(true));
      });
  };
};
