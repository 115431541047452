import axios from "axios";
import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";

export const submitPatientReportId = (reportId) => {
  return {
    type: types.SUMBITPATIENTREPORTID,
    payload: reportId,
  };
};

export const showToastHandler = (isShow) => {
  return {
    type: types.SHOWTOAST,
    payload: isShow,
  };
};

export const emptyPatientData = () => {
  return {
    type: types.EMPTYPATIENTDATA,
  };
};

export const setDefaultSubmitStatus = () => {
  return {
    type: types.SETDEFAULTSUBMITSTATUS,
  };
};

export const setIsLoadingPatientData = (boolean) => {
  return {
    type: types.IS_LOADING_PATIENT_DATA,
    payload: boolean,
  };
};

export const setIsSubmittingReview = (boolean) => {
  return {
    type: types.IS_SUBMITTING_REPORT,
    payload: boolean,
  };
};

export const setIsErrorGettingPatientData = (status) => {
  return {
    type: types.IS_ERROR_GETTING_PATIENT_DATA,
    payload: status,
  };
};

export const setIsErrorGettingBaseRhythmData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_RHYTHM_DATA,
    payload: boolean,
  };
};

export const setIsErrorGettingAcuityData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_ACUITY_DATA,
    payload: boolean,
  };
};

export const isLoadingCancelReview = (boolean) => {
  return {
    type: types.IS_LOADING_CANCEL_REVIEW,
    payload: boolean,
  };
};

export const getPatientData = (id, status, sourceToken) => {
  let boolean = false;
  if (status === "completed") {
    boolean = true;
  }
  return (dispatch) => {
    dispatch(setIsLoadingPatientData(true));
    CrpAPI.get(`patientDetail/${id}?complete=${boolean}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      cancelToken: sourceToken,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setIsLoadingPatientData(false));
          dispatch(setIsErrorGettingPatientData(""));
          dispatch({
            type: types.GET_PATIENTDATA_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(setIsLoadingPatientData(false));
        if (axios.isCancel(err)) {
          return;
        }
        if (err.response && err.response.data) {
          dispatch(
            setIsErrorGettingPatientData(
              err.response.data.error.devMsg.slice(0, 8)
            )
          );
          return;
        }
        dispatch(setIsErrorGettingPatientData("error"));
      });
  };
};

export const getBaseRythmData = () => {
  return (dispatch) => {
    CrpAPI.get(`rhythm`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETBASERHYTHM,
            payload: res.data.data,
          });
          dispatch(setIsErrorGettingBaseRhythmData(false));
        }
      })
      .catch((err) => {
        dispatch(setIsErrorGettingBaseRhythmData(true));
      });
  };
};

export const getAcuity = () => {
  return (dispatch) => {
    CrpAPI.get(`acuity`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETACUITY,
            payload: res.data.data,
          });
          dispatch(setIsErrorGettingAcuityData(false));
        }
      })
      .catch((err) => {
        dispatch(setIsErrorGettingAcuityData(true));
      });
  };
};

export const reviewSubmit = (review) => {
  return (dispatch) => {
    dispatch(setIsSubmittingReview(true));
    CrpAPI.post(`review`, review, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.SUBMITREVIEW,
            payload: true,
          });
          dispatch(showToastHandler(true));
          dispatch(setIsSubmittingReview(false));
        }
      })
      .catch((err) => {
        dispatch({
          type: types.SUBMITREVIEW,
          payload: false,
        });
        dispatch(setIsSubmittingReview(false));
      });
  };
};

export const updateReportStatus = (patientId, status) => {
  const statusUpdateObject = {
    patientId,
    status,
  };
  return async (dispatch) => {
    try {
      dispatch(isLoadingCancelReview(true));
      const res = await CrpAPI.put(
        `status/${patientId}/${status}`,
        statusUpdateObject,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        dispatch(isLoadingCancelReview(false));
        if (res.data.data === "Update successfully..") {
          dispatch({
            type: types.REVIEWSTATUS,
            payload: true,
          });
        }
      }
    } catch (err) {
      dispatch(isLoadingCancelReview(false));
    }
  };
};
