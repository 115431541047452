import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Errorpage from "./Components/Errorpage";
import * as actions from "./Store/User";
import { timeRemainingOnTokenMs } from "./Auth/AuthUtilities";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Dashboard from "./Containers/Dashboard/Dashboard";
import EkgList from "./Containers/EkgList/EkgList";
import EkgReview from "./Containers/EkgReview/EkgReview";
import EkgResources from "./Containers/EkgResources/EkgResources";
import EkgReport from "./Containers/EkgReport/EkgReport";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import AccessDeniedContent from "./Components/Shared/AccessDeniedContent/AccessDeniedContent";
import {
  ADMIN_HOME,
  ADMIN_ECGLIST,
  ADMIN_RESOURCES,
  ADMIN_REPORT,
  ADMIN_CLINCIAN_REVIEW,
} from "./Auth/Routes";

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: "#FFFFFF",
    margin: "50px 0 0 0 ",
    padding: "40px",
  },
}));

const AppAdmin = (props) => {
  const [
    isClinicianServiceProviderEnabled,
    setIsClinicianServiceProverEnabled,
  ] = useState(false);

  useEffect(() => {
    var features = props.userstate.data.teams[0].features;
    if (features != undefined && features.length != 0) {
      var crpFeature = features.filter(
        (item) => item.feature === "clinician-review-portal"
      );
      setIsClinicianServiceProverEnabled(crpFeature[0].enabled);
    }
  }, []);

  const classes = useStyles();
  const { i18n } = useTranslation();
  let content =
    isClinicianServiceProviderEnabled &&
    props.userstate.data.profile.permissions.isAdmin ? (
      <div className="container-fluid p-0">
        <div>
          <div>
            <Switch>
              <Route path={ADMIN_HOME} component={Dashboard} />
              <Route path={ADMIN_ECGLIST} component={EkgList} exact />
              <Route path={ADMIN_CLINCIAN_REVIEW} component={EkgReview} />
              <Route path={ADMIN_RESOURCES} component={EkgResources} exact />
              <Route path={ADMIN_REPORT} component={EkgReport} exact />
            </Switch>
          </div>
        </div>
      </div>
    ) : (
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          className={classes.accessDeniedContent}
        >
          <AccessDeniedContent />
        </Grid>
      </Container>
    );

  function getSupportedLocales() {
    if (!process.env.REACT_APP_I18N_SUPPORTED_LOCALES) {
      return ["en"];
    }
    return process.env.REACT_APP_I18N_SUPPORTED_LOCALES.split(",");
  }

  function isSupportedLocale(locale) {
    return getSupportedLocales().includes(locale);
  }

  function getLocale() {
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale)) {
      return browserLocale;
    }

    // fallback to base locale if we have it
    if (browserLocale && browserLocale.indexOf("-") !== -1) {
      // en-US -> 'en' or 'de-DE' -> 'de'
      const baseLocale = browserLocale.split("-")[0];
      if (isSupportedLocale(baseLocale)) {
        return baseLocale;
      }
    }
  }

  useEffect(() => {
    // let language = localStorage.getItem("kardiapro_region") || "en";

    let locale = getLocale();
    i18n.changeLanguage(locale);
    if (
      !props.gstate.user.profile ||
      props.gstate.user.profile.id !== props.userstate.data.profile.id
    ) {
      if (props.userstate) {
        props.onAddUser(props.userstate.data);
      } else {
        return <Errorpage />;
      }
    }
    return () => {
      props.onAddUser("logOut");
      // localStorage.removeItem("currentTab");
    };
  }, [props.userstate.data]);

  if (!(timeRemainingOnTokenMs() > 0)) {
    //redirect to home
    // props.onUserLogout();
    window.location.replace("http://" + window.location.host + "/login");
  } else if (props.gstate.user.profile) {
    return (
      <div>
        <BrowserRouter>{content}</BrowserRouter>
      </div>
    );
  } else return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    gstate: state,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onUserLogout: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAdmin);
