import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";
import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import ResourceContent from "../../Components/ResourceContent/ResourceContent";
import EcgLoader from "../../Components/Shared/EcgLoader";
import { getResourceData } from "../../Store/EkgResources/EkgResourcesActions";
import { useStyles } from "./EkgResouresStyles";
import { darkBluishGrey, black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

function EkgResources() {
  const classes = useStyles();

  const team = useSelector((state) => state.user.team);
  const profile = useSelector((state) => state.user.profile);
  const isFetchingResourceContent = useSelector(
    (state) => state.ekgResource.isFetchingResourceContent
  );
  const resourcesContent = useSelector(
    (state) => state.ekgResource.resourcesContent
  );
  const isErrorGettingResourceData = useSelector(
    (state) => state.ekgResource.isErrorGettingResourceData
  );

  const isAdmin = profile.permissions.isAdmin;
  const isPhysician = profile.permissions.isPhysician;

  const dispatch = useDispatch();

  useEffect(() => {
    if (team && team.features && team.features.length) {
      let teleconnectFeature = team.features.filter(
        (item) => item.feature === "teleconnect"
      );
      let crpFeature = team.features.filter(
        (item) => item.feature === "clinician-review-portal"
      );
      dispatch(
        getResourceData(
          crpFeature[0].enabled && isAdmin,
          crpFeature[0].enabled && isPhysician,
          teleconnectFeature[0].enabled && isAdmin,
          teleconnectFeature[0].enabled && isPhysician
        )
      );
    }
  }, []);

  let resourceOne = resourcesContent?.[
    resourcesContent.length - resourcesContent.length
  ].data.map((item, idx) => (
    <ResourceContent
      key={idx}
      heading={item.heading}
      desc={item.desc}
      file={item.file_url}
    />
  ));

  let resourceTwo =
    resourcesContent && resourcesContent.length - 1 > 0
      ? resourcesContent?.[
          resourcesContent.length - (resourcesContent.length - 1)
        ].data.map((item, idx) => (
          <ResourceContent
            key={idx}
            heading={item.heading}
            desc={item.desc}
            file={item.file_url}
          />
        ))
      : "";

  let resourceThree =
    resourcesContent && resourcesContent.length - 2 > 0
      ? resourcesContent?.[
          resourcesContent.length - (resourcesContent.length - 2)
        ].data.map((item, idx) => (
          <ResourceContent
            key={idx}
            heading={item.heading}
            desc={item.desc}
            file={item.file_url}
          />
        ))
      : "";

  let resourceFour =
    resourcesContent && resourcesContent.length - 3 > 0
      ? resourcesContent?.[
          resourcesContent.length - (resourcesContent.length - 3)
        ].data.map((item, idx) => (
          <ResourceContent
            key={idx}
            heading={item.heading}
            desc={item.desc}
            file={item.file_url}
          />
        ))
      : "";

  let resourceFive =
    resourcesContent && resourcesContent.length - 4 > 0
      ? resourcesContent?.[
          resourcesContent.length - (resourcesContent.length - 4)
        ].data.map((item, idx) => (
          <ResourceContent
            key={idx}
            heading={item.heading}
            desc={item.desc}
            file={item.file_url}
          />
        ))
      : "";

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item className={classes.topMargin}>
          <MuiTypography
            fontSize="24px"
            fontFamily={fontFamily}
            lineHeight="28px"
            letterSpacing="0.25px"
            fontWeight={500}
            color={black}
          >
            {"Resources"}
          </MuiTypography>
          <BottomLineTitle />
        </Grid>
      </Grid>
      <Box>
        <Box component="span" color={darkBluishGrey} borderBottom={1}>
          Dashboard
        </Box>
      </Box>

      {isFetchingResourceContent && (
        <Box>
          <EcgLoader />
        </Box>
      )}

      {!isFetchingResourceContent && resourcesContent && (
        <Paper className={classes.paperRoot}>
          <Box>
            <Typography className={classes.heading}>
              Resources and Guides
            </Typography>
          </Box>
          <Box className={classes.generalSubHeading}>
            {
              <Typography className={classes.subHeading}>
                {resourcesContent.length > 0
                  ? resourcesContent?.[
                      resourcesContent.length - resourcesContent.length
                    ].title
                  : ""}
              </Typography>
            }
            {resourceOne}
          </Box>
          <Box className={classes.generalSubHeading}>
            {
              <Typography className={classes.subHeading}>
                {resourcesContent.length - 1 > 0
                  ? resourcesContent?.[
                      resourcesContent.length - (resourcesContent.length - 1)
                    ].title
                  : ""}
              </Typography>
            }
            {resourceTwo}
          </Box>
          <Box className={classes.generalSubHeading}>
            {
              <Typography className={classes.subHeading}>
                {resourcesContent.length - 2 > 0
                  ? resourcesContent?.[
                      resourcesContent.length - (resourcesContent.length - 2)
                    ].title
                  : ""}
              </Typography>
            }
            {resourceThree}
          </Box>
          <Box className={classes.generalSubHeading}>
            {
              <Typography className={classes.subHeading}>
                {resourcesContent.length - 3 > 0
                  ? resourcesContent?.[
                      resourcesContent.length - (resourcesContent.length - 3)
                    ].title
                  : ""}
              </Typography>
            }
            {resourceFour}
          </Box>
          <Box className={classes.generalSubHeading}>
            {
              <Typography className={classes.subHeading}>
                {resourcesContent.length - 4 > 0
                  ? resourcesContent?.[
                      resourcesContent.length - (resourcesContent.length - 4)
                    ].title
                  : ""}
              </Typography>
            }
            {resourceFive}
          </Box>
        </Paper>
      )}
      {isErrorGettingResourceData && (
        <Box>Cant Get Resource Data at the moment</Box>
      )}
    </Box>
  );
}

export default EkgResources;
