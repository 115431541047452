import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Errorpage from "./Components/Errorpage";
import * as actions from "./Store/User";
import { timeRemainingOnTokenMs } from "./Auth/AuthUtilities";
import { connect } from "react-redux";
import Dashboard from "./Containers/Dashboard/Dashboard";
import EkgList from "./Containers/EkgList/EkgList";
import EkgResources from "./Containers/EkgResources/EkgResources";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import AccessDeniedContent from "./Components/Shared/AccessDeniedContent/AccessDeniedContent";
import EkgReview from "./Containers/EkgReview/EkgReview";
import {
  CLINICIAN_HOME,
  CLINICIAN_ECGLIST,
  CLINCIAN_REVIEW,
  RESOURCES,
} from "./Auth/Routes";

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: "#FFFFFF",
    margin: "50px 0 0 0 ",
    padding: "40px",
  },
}));

const App = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [
    isClinicianServiceProviderEnabled,
    setIsClinicianServiceProverEnabled,
  ] = useState(false);
  const userData = props.userstate.data;

  useEffect(() => {
    var features = props.userstate?.data?.teams?.[0]?.features;
    if (features != undefined && features.length != 0) {
      var crpFeature = features.filter(
        (item) => item.feature === "clinician-review-portal"
      );
      setIsClinicianServiceProverEnabled(crpFeature[0]?.enabled);
    }
  }, []);

  let content =
    isClinicianServiceProviderEnabled &&
    userData.profile.permissions.isPhysician ? (
      <div className="container-fluid p-0">
        <div>
          <div>
            <Switch>
              <Route path={CLINICIAN_HOME} exact component={Dashboard} />
              <Route path={CLINICIAN_ECGLIST} exact component={EkgList} />
              <Route path={CLINCIAN_REVIEW} exact component={EkgReview} />
              <Route path={RESOURCES} exact component={EkgResources} />
            </Switch>
          </div>
        </div>
      </div>
    ) : (
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          className={classes.accessDeniedContent}
        >
          <AccessDeniedContent />
        </Grid>
      </Container>
    );

  function getSupportedLocales() {
    if (!process.env.REACT_APP_I18N_SUPPORTED_LOCALES) {
      return ["en"];
    }
    return process.env.REACT_APP_I18N_SUPPORTED_LOCALES.split(",");
  }

  function isSupportedLocale(locale) {
    return getSupportedLocales().includes(locale);
  }

  function getLocale() {
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale)) {
      return browserLocale;
    }

    // fallback to base locale if we have it
    if (browserLocale && browserLocale.indexOf("-") !== -1) {
      // en-US -> 'en' or 'de-DE' -> 'de'
      const baseLocale = browserLocale.split("-")[0];
      if (isSupportedLocale(baseLocale)) {
        return baseLocale;
      }
    }
  }

  useEffect(() => {
    let locale = getLocale();
    i18n.changeLanguage(locale);

    if (
      !props.gstate.user.profile ||
      props.gstate.user.profile.id !== userData.profile.id
    ) {
      if (userData) {
        props.onAddUser(userData);
      } else {
        return <Errorpage />;
      }
    }
    return () => {
      props.onAddUser("logOut");
    };
  }, [userData]);

  if (!(timeRemainingOnTokenMs() > 0)) {
    //redirect to home
    window.location.replace("http://" + window.location.host + "/login");
  } else if (props.gstate.user.profile) {
    return (
      <div>
        <BrowserRouter>{content}</BrowserRouter>
      </div>
    );
  } else return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    gstate: state,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onLogOut: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
