export const changeDateTimeFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime.replace(/ /g, "T"));
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, "0");
  const date = rawTimeDate.getDate().toString().padStart(2, "0");
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${month}/${date}/${year}`;

  const time = rawTimeDate.toLocaleTimeString("en-IT");
  const formattedTime = time.slice(0, -6).trim().padStart(5, "0");
  const formattedDateTime = formattedDate + " " + formattedTime + " ";
  return formattedDateTime;
};

export const changeDateFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, "0");
  const date = rawTimeDate.getDate().toString().padStart(2, "0");
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${month}/${date}/${year}`;
  return formattedDate;
};

export const yearMonthDateFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, "0");
  const date = rawTimeDate.getDate().toString().padStart(2, "0");
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${year}-${month}-${date}`;
  return formattedDate;
};

export const getMeridiem = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const time = rawTimeDate.toLocaleTimeString("en-IT");
  const meridiem = time.slice(time.length - 2, time.length);
  return meridiem;
};

export const getDateFromString = (dateString) => {
  const dateArr = dateString.split("-");
  const dateFields = {
    date: dateArr[2],
    month: dateArr[1],
    year: dateArr[0],
  };
  const formattedDate = `${dateFields.month}/${dateFields.date}/${dateFields.year}`;
  return formattedDate;
};

// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
