/* eslint-disable */
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./Store";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import AppAdmin from "./AppAdmin";
import "./i18n";

//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
//export const store = createStore(rootReducer, applyMiddleware(reduxThunk));
const store = createStore(rootReducer, applyMiddleware(reduxThunk));
window.renderClinician = (containerId, history, state_, app) => {
  if (app === "clinician") {
    ReactDOM.render(
      <div className="">
        <Provider store={store}>
          <App history={history} mode="MICROFRONTEND" userstate={state_} />
        </Provider>
      </div>,
      document.getElementById(containerId)
    );
  } else if (app === "admin") {
    ReactDOM.render(
      <div className="">
        <Provider store={store}>
          <AppAdmin history={history} mode="MICROFRONTEND" userstate={state_} />
        </Provider>
      </div>,
      document.getElementById(containerId)
    );
  }
  serviceWorker.unregister();
};

window.unmountClinician = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

if (!document.getElementById("Clinician-container")) {
  ReactDOM.render(
    <div className="">
      <Provider store={store}>
        <App mode={"STANDALONE"} />
      </Provider>
    </div>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
}
