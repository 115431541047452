import React from "react";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./ModalStyles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

// Usage : It accepts following props :
// modalType : "alert or confirm"
// open : Condition for opening modal (true/false)
// closeModalHandler : Function to set open to false
// icon : Icon to be shown in modal
// iconAltText : String to be shown if icon is not visible
// heading : Heading of the modal
// descriptionLine1 : Description Line 1
// descriptionLine2 : Description Line 2
// confirmHandler : Function to be exectued if user Clicks on confirm button
// confirmButtonText : Name of Confirm Button
// declineButtonText : Name of Decline Button

function CustomModal(props) {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={props.closeModalHandler}
      BackdropComponent={Backdrop}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.modalWarningImage}>
          <img src={props.icon} alt={props.iconAltText} />
        </Box>

        <h1 className={classes.modalHeading}>{props.heading}</h1>
        <p className={classes.modalDescription}>{props.descriptionLine1}</p>
        {props.descriptionLine2 !== "" && (
          <p className={classes.modalDescription}>{props.descriptionLine2}</p>
        )}

        <Box className={classes.modalButtonContainer}>
          <Button
            className={`${classes.modalButton} ${classes.leaveButton}`}
            onClick={props.confirmHandler}
          >
            {props.confirmButtonText}
          </Button>
          {props.modalType === "confirm" && (
            <Button
              className={`${classes.modalButton} ${classes.stayButton}`}
              onClick={props.closeModalHandler}
            >
              {props.declineButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomModal;
